<template>
    <component
        :is="dynamicComponent"
    ></component>  
</template>

<script>
export default {
    components: {
        
    },
    data(){
        return {
            breadcrumb: [
                {title: 'Home', path:'/'},
                {title: this.$route.name, path:'#'}
            ],
            slug: ''
        }
    },
    computed: {
        dynamicComponent(){            
            let fileName = this.$options.filters.capitalize(this.slug);
            return () => import(`../components/Students/${fileName}`);
        }
    },      
    methods: {}, 
    created: function(){
        this.slug = this.$route.meta.slug,
        this.name = this.$route.name   
        
    },
    watch: {
        '$route.meta.slug'(){
            this.slug = this.$route.meta.slug
        },
        '$route.name'(){
            this.name = this.$route.name            
        }
    }, 
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString();
            let strArry = value.split('-')
            if(strArry.length > 1){
                return strArry[0].charAt(0).toUpperCase() + strArry[0].slice(1) + strArry[1].charAt(0).toUpperCase() + strArry[1].slice(1)
            }
            else 
                return value.charAt(0).toUpperCase() + value.slice(1)
        }
    } 
}
</script>